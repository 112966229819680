<template>
  <div class="user-avatar">
    <NuxtLink class="not-login" v-if="!data.basicInfo.id" @click="login">
      <img class="avatar" src="@/assets/icons/pc_home_mhead_icon.svg" />
      <span class="text-f14">登录</span>
    </NuxtLink>
    <n-dropdown v-else class="nav-list" trigger="hover" label-field="name" :options="data.hrefList" @select="select">
      <div class="is-login">
        <HeaderUserAvatar
          class="avatar"
          :src="data.basicInfo.avatar"
          :size="28"
          :identificationSize="10"
          :showIdentification="true"
          :user-type="getUserType(data.basicInfo.identityInfoType)"
        />
        <span class="text-f14">{{ data.basicInfo.nickName }}</span>
      </div>
    </n-dropdown>
  </div>
</template>

<script setup lang="ts">
import { MagicHouseAppUserDto } from "@/apis/api";
import { useMessage } from "naive-ui";
import userStore from "@/stores/user";
const message = useMessage();
const useUser = userStore();
const router = useRouter();
const config = useRuntimeConfig();

interface dataType {
  basicInfo: MagicHouseAppUserDto;
  hrefList: { name: string; key: number }[];
}

const data = reactive<dataType>({
  basicInfo: {},
  hrefList: [
    {
      name: "我的主页",
      key: 1,
    },
    {
      name: "个人中心",
      key: 2,
    },
    {
      name: "马上设计",
      key: 3,
    },
    {
      name: "退出登录",
      key: 4,
    },
  ],
});

// 登录
function login() {
  if (!data.basicInfo.id) toPassProt(location.href);
}
// 退出登录
function logout() {
  let date = new Date();
  date.setTime(date.getTime() - 10000); // 当前时间减去10000，确保已过期
  document.cookie = ".AspNet.SharedCookie=; domain=.shixianjia.com; expire=" + date.toString() + "; path=/";
  useUser.logout();
}
// 选择二级菜单
function select(code: number) {
  switch (code) {
    //个人主页
    case 1:
      const routerUrl = router.resolve({
        path: "/personalHomePage",
      });
      window.open(routerUrl.href, "_blank");
      break;
    // 个人中心
    case 2:
      window.open(`${config.public.VITE_USER_CENTER}/user-home`, "_blank");
      break;
    // 3D设计
    case 3:
      message.loading("跳转3D设计");
      break;
    // 退出登录
    case 4:
      logout();
      break;
  }
}

onMounted(() => {
  data.basicInfo = computed(() => useUser.getBasicInfo) as MagicHouseAppUserDto;
  console.log("用户信息", data.basicInfo);
});
</script>

<style lang="less" scoped>
// 用户头像
.user-avatar {
  @apply flex items-center cursor-pointer;
  height: 100%;
  margin-left: 23px;
  // 未登录,已登录
  .not-login,
  .is-login {
    @apply flex items-center;
    height: 28px;
  }

  .avatar {
    width: 28px;
    height: 28px;
    margin-right: 6px;
    border-radius: 50%;
  }
}
</style>
