<template>
  <Transition>
    <div class="main">
      <div class="imIcon" id="IM-btn">
        <img :src="imIcon" alt="">
      </div>
      <div class="toTopIcon">
        <img :src="toTopIcon" alt="" @click="backTop">
      </div>
    </div>
  </Transition>
</template>

<script setup >
import imIcon from '@/assets/icons/home_customerservice.svg'
import toTopIcon from '@/assets/icons/home_topcome.svg'
const showBackTop = ref(false)

const props = defineProps({
  fromTop: {
    type: Number,
    default: 300
  }
})
// 回到顶部
const backTop = () => {
  let top = document.documentElement.scrollTop || document.body.scrollTop
  const timeTop = setInterval(() => {
    document.body.scrollTop = document.documentElement.scrollTop = top -= 50
    if (top <= 0) {
      clearInterval(timeTop)
    }
  }, 5)
}
// 监听页面滚动
const handleScroll = () => {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  if (scrollTop > props.fromTop) {
    showBackTop.value = true
  } else if (scrollTop < props.fromTop) {
    showBackTop.value = false
  }
  // console.log(scrollTop, '滚动距离')
}
// 创建IM
function createUdeskIM() {
  console.log('创建IM');
  (function (a, h, c, b, f, g) {
    // console.log('a', a);
    // console.log('h', h);
    // console.log('c', c);
    // console.log('b', b);
    // console.log('f', f);
    // console.log('g', g);
    a["UdeskApiObject"] = f;
    a[f] = a[f] || function () { (a[f].d = a[f].d || []).push(arguments) };
    g = h.createElement(c);
    g.async = 1;
    g.charset = "utf-8";
    g.src = b; c = h.getElementsByTagName(c)[0];
    c.parentNode.insertBefore(g, c)
  })(window, document, "script", "https://assets-cli.s4.udesk.cn/im_client/js/udeskApi.js", "ud");

  ud({
    "code": "7545j9b",
    "link": "https://udesk-xs-cd-01.s4.udesk.cn/im_client/?web_plugin_id=55076",
    "agent_id": "25002", //客服id
    "color": "#000",
    "onlineText": "在线咨询实现家客服", //客服在线显示文本
    "offlineText": "客服不在",  //客服离线显示文本
    "targetSelector": "#IM-btn",
    "pop": {  //消息提示气泡
      "direction": "left",
      "arrow": {
        "top": 0,
        "left": "70%"
      },
      "offset": {
        "top": 10,
        "left": 0
      }
    },
    "product": {
      "title": "Apple iPhone 7",
      "url": "http://item.jd.com/3133829.html?cu=true&utm_source…erm=9457752645_0_11333d2bdbd545f1839f020ae9b27f14",
      "image": "http://img14.360buyimg.com/n1/s450x450_jfs/t3157/63/1645131029/112074/f4f79169/57d0d44dN8cddf5c5.jpg",
      "价格": "￥6189.00",
      "促销价": "￥6188.00", //自定义属性
      "促销价_style": "color:#eee;background:#ccc", //自定义属性样式
    },
  });

  // ud("dataTrace", "order", {
  //   order_no: '1',
  //   name: '测试订单',
  //   url: 'http://item.jd.com/3133829.html?cu=true&utm_source…erm=9457752645_0_11333d2bdbd545f1839f020ae9b27f14',
  //   price: 16.8,
  //   order_at: new Date(),
  //   pay_at: new Date(),
  //   status: 'wait_pay',
  //   remark: '备注',
  //   consignee_name: '张三',
  //   consignee_phone: '01012345',
  //   consignee_address: '北京市大兴区',
  //   commodit_num: 88,
  //   commodities: [
  //     {
  //       commodit_name: '牛奶A',
  //       commodit_no: 'NO123456',
  //       commodit_count: 1,
  //       commodit_fee: '46.5'
  //     }
  //   ]
  // });
}
onMounted(() => {
  window.addEventListener('scroll', handleScroll, true) // 监听页面滚动
  if (process.client) createUdeskIM()
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll, true)
})
</script>

<style lang="less" scoped>
.main {
  @apply flex justify-center flex-col items-center;

  .imIcon,
  .toTopIcon {
    width: 58px;
    height: 58px;
    @apply flex justify-center items-center;

    img {
      width: 100%;
      height: 100%;
      @apply cursor-pointer;
    }
  }
}

// 动画
.v-enter-active,
.v-leave-active {
  transition: opacity .3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>