<template>
  <div class="search">
    <div class="treasure-hunt-map">寻宝图</div>
    <div class="search-input">
      <div class="city">
        <span>成都</span>
        <icon-caret-down />
      </div>
      <div class="ipt">
        <input type="text" @keydown.enter="search" v-model="data.msg" placeholder="搜索" />
      </div>
      <img src="@/assets/icons/pc_home_seach_icon.svg" @click="search" />
    </div>
  </div>
</template>

<script setup lang="ts">
const data = reactive({
  msg: "",
});
function search() {}
</script>

<style lang="less" scoped>
.search {
  @apply flex items-center;
  box-sizing: border-box;
  margin-left: 20px;
  .treasure-hunt-map {
    @apply text-f16 shrink-0 cursor-pointer;
    margin-right: 12px;
  }
  .search-input {
    @apply relative flex items-center box-border;
    width: 248px;
    height: 40px;
    padding: 0 16px;
    border-radius: 20px;
    background: #313131;
    .city {
      @apply flex-none text-f14 cursor-pointer text-left;
      color: #c0c0c0;
      width: 55px;
      line-height: 18px;
      border-right: 1px solid #979797;
    }
    .ipt {
      @apply flex-auto box-border;
      width: 50px;
      padding: 0 16px;
      input {
        @apply text-f14 text-fc3;
        width: 100%;
        height: 100%;
        background: #313131;
      }
    }

    img {
      @apply absolute top-1/2 cursor-pointer;
      right: 16px;
      margin-top: -8px;
    }
  }
}
</style>
