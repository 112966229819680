<template>
  <div class="question-msg-card">
    <header class="text-f16 font-medium relative">
      <span>消息</span>
      <div class="bottom-line absolute"></div>
    </header>
    <ul>
      <li class="msg-item box-border flex relative cursor-pointer">
        <div class="label text-f14 font-medium">【问答消息】</div>
        <div class="msg text-f14 hidden_row2">@北北 的提问邀请你回答 8平中式 风格怎么装？@北北 的提问邀请你回答 8平中式 风格怎么装？</div>
        <div class="bottom-line absolute"></div>
      </li>
    </ul>
    <footer class="text-center cursor-pointer">查看全部消息</footer>
  </div>
</template>

<script setup lang="ts">
// import * as signalR from "@microsoft/signalr";
// console.log('signalR', signalR);

const data = reactive({
  connection: null,
});

// 初始化连接
function signalrInit() {
      const configEnv = useRuntimeConfig();
  const connectionServer = (data.connection = new signalR.HubConnectionBuilder().withUrl(`${configEnv.public.VITE_API_HOST}/houseNotifications`).build());
  // 开始链接
  connectionServer
    .start()
    .then(() => {
      console.log("链接成功");
    })
    .catch((error) => {
      console.log("链接失败", error);
    });
  // 断开链接
  connectionServer.onclose((error) => {
    console.log("服务断开");
  });
  // 监听消息
  connectionServer.on("ReceiveMessage", (message) => {
    console.log("消息", JSON.parse(message));
  });
}

onMounted(() => {
  // signalrInit()
});
</script>

<style lang="less" scoped>
.question-msg-card {
  width: 335px;
  min-height: 100px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.12);

  header,
  footer {
    line-height: 50px;
    padding: 0 17px;
  }

  footer {
    color: rgba(0, 0, 0, 0.6);

    &:hover {
      color: #f39e00;
    }
  }

  ul {
    // min-height: 100px;
    .msg-item {
      padding: 15px 17px;

      .label {
        // min-width: 75px;
        margin-right: 15px;
        text-align: left;
        line-height: 18px;
      }

      .msg {
        flex: 1;
        line-height: 18px;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }

  .bottom-line {
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 300px;
    height: 1px;
    background: rgba(0, 0, 0, 0.08);
  }
}
</style>
