<template>
  <ul class="header-nav" :style="{ background: props.bgColor, color: props.fontColor }">
    <li class="header-nav-item" v-for="item in data.navList">
      <a-dropdown :popup-max-height="false" trigger="hover">
        <NuxtLink class="nav-title" :class="{ active: data.currentPath === item.path }" :to="item.path">
          <span v-if="item.name !== '看看我家'">{{ item.name }}<icon-caret-down v-if="item.children" /></span>
          <img v-else src="@/assets/icons/pc_personal_kkwj.svg" alt="" />
        </NuxtLink>
        <template #content>
          <div class="nav-child" v-if="item.children">
            <NuxtLink class="nav-child-item" v-for="(child, index) in item.children" :key="index" :to="child.path" target="_blank">{{ child.name }}</NuxtLink>
          </div>
        </template>
      </a-dropdown>
    </li>
  </ul>
</template>

<script setup lang="ts">
const route = useRoute();
const config = useRuntimeConfig();
const props = defineProps({
  bgColor: {
    type: String,
    default: "#1A1A1A",
  },
  fontColor: {
    type: String,
    default: "#FFFFFF",
  },
});

const data = reactive({
  currentPath: "/",
  navList: [
    {
      name: "首页",
      path: "/",
    },
    {
      name: "专业问答",
      path: "/decoration/questions",
    },
    {
      name: "优家竞选",
      path: "/youjiaCampaign",
      children: [
        {
          name: "邀约设计",
          path: "/youjiaCampaign/findDesigner",
        },
        {
          name: "逛逛装修",
          path: "/youjiaCampaign/findDecorationCompany",
        },
        {
          name: "设计竞选",
          path: "/youjiaCampaign/designCampaign",
        },
      ],
    },
    {
      name: "所见所得",
      path: "/design/realCase",
    },
    {
      name: "好物上线",
      path: config.public.VITE_MALL,
      children: [
        {
          name: "首页",
          path: config.public.VITE_MALL,
        },
        {
          name: "购物车",
          path: `${config.public.VITE_MALL}/cart`,
        },
        {
          name: "我的购买",
          path: `${config.public.VITE_MALL}/member/center`,
        },
      ],
    },
    {
      name: "设计榜单",
      path: "/designList",
    },
    {
      name: "看看我家",
      path: "",
    },
    {
      name: "下载APP",
      path: "/downloadApp",
    },
  ],
});

watch(
  () => route.fullPath,
  (newV, oldV) => {
    // console.log('newV', newV.split('/'));
    data.currentPath = "/" + newV.split("/")[1];
  },
  {
    immediate: true,
  }
);
</script>

<style lang="less" scoped>
.header-nav {
  @apply flex items-center justify-between text-w;
  width: 800px;
  height: 100%;

  .header-nav-item {
    @apply cursor-pointer text-f16;
    // margin-right: 40px;
    .nav-title {
      @apply text-w text-f16;
    }
    .nav-title:hover {
      @apply text-oc1-2;
    }

    &:nth-last-of-type(1) {
      margin-right: 0;
    }
  }
}
.nav-child {
  @apply bg-w cursor-pointer;
  width: 136px;
  min-height: 50px;
  border-radius: 4px;
  padding: 10px 0;
  .nav-child-item {
    @apply text-b text-f16 cursor-pointer bg-w;
    width: 100%;
    height: 44px;
    text-align: center;
    line-height: 44px;
    &:hover {
      background-color: #ebebeb;
    }
  }
}

.active {
  color: #ffad28 !important;
}
.arco-icon-caret-down {
  margin-left: 4px;
}
.arco-dropdown-open .arco-icon-caret-down {
  transform: rotate(180deg);
  transition: all 0.2s;
}
</style>
